import React, {useState} from 'react';
import styled from "styled-components";
import twitterX from "../media/twitter-x.svg";
import {Button, Column, Input, SubTitle, Title, Error} from "./styled/components";


interface TwitterFormProps {
    twitterInfo?: any;
    profile?: any;
    connectTwitter: (twitter: string) => void;
    error?: string
}


const TwitterForm: React.FC<TwitterFormProps> = ({ twitterInfo, profile, connectTwitter, error }) => {

    const [twitter, _setTwitter] = useState<string>('');

    const setTwitterInput = (e: any) => {
        _setTwitter(e.target.value);
    }

    return twitterInfo ? (
        <>
            <Column>
                <Title>Hey {twitterInfo?.legacy?.name}</Title>
                <SubTitle>X is connected</SubTitle>
                <TwitterIcon src={twitterX} />
            </Column>
            <Button onClick={() => window.location.href = `/${profile?.username}`}>GO</Button>
        </>
    ) : (
        <>
            <div>
                <Title>Welcome <span>{profile?.fullName}</span></Title>
                <SubTitle>Type your twitter please</SubTitle>
            </div>
            <Column>
                <Input placeholder="Username or URL" type="text" value={twitter} onChange={setTwitterInput} />
                <Error>{error}</Error>
                <Button onClick={() => connectTwitter(twitter)}>Connect</Button>
                <OptionalButton onClick={() => window.location.href = `/${profile?.username}`}>Don't Have</OptionalButton>
            </Column>
        </>
    );
};

export default TwitterForm;


const OptionalButton = styled(Button)`
    background-color: #94949e;
`;

const TwitterIcon = styled.img`
    width: 75px;
    height: 75px;
`
