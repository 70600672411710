import React, { createContext, useContext, ReactNode } from 'react';

interface MobileContextProps {
    isMobile: boolean;
}

const MobileContext = createContext<MobileContextProps | undefined>(undefined);

export const MobileProvider = ({
                                   children,
                                   isMobile,
                               }: {
    children: ReactNode;
    isMobile: boolean;
}) => {
    return (
        <MobileContext.Provider value={{ isMobile }}>
            {children}
        </MobileContext.Provider>
    );
};

export const useMobileContext = (): MobileContextProps => {
    const context = useContext(MobileContext);
    if (!context) {
        throw new Error('useMobileContext must be used within a MobileProvider');
    }
    return context;
};

const useIsMobile = (): boolean => {
    const context = useContext(MobileContext);
    if (!context) {
        throw new Error('useIsMobile must be used within a MobileProvider');
    }
    return context.isMobile;
};

export default useIsMobile;
