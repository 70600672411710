import React, {useCallback, useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
// @ts-ignore
import Autosuggest from 'react-autosuggest';
import axios from "axios";



interface MyModalProps {
    show: boolean;
    handleClose: () => void;
    handleNext: (inputValue: string) => Promise<any>;
    QR: string;
    URL: string;
}



const CreateEventModal: React.FC<MyModalProps> = ({ show, QR, URL, handleClose, handleNext }) => {
    const [inputValue, setInputValue] = useState('');
    const [error, setError] = useState('')
    const [createdEventQR, setQR] = useState('')
    const [createdEventURL, setURL] = useState('')
    const [copySuccess, setCopySuccess] = useState<string>('');
    const [suggestions, setSuggestions] = useState([]);

    const modalRef = useRef<HTMLDivElement>(null);

    const close = useCallback(() => {
        handleClose();
        setInputValue('');
        setError('');
        setQR('');
    }, [handleClose])

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
                close();
            }
        };

        if (show) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [show, close]);

    const handleCopyUrl = () => {
        const url = createdEventURL || URL;
        navigator.clipboard.writeText(url).then(
            () => {
                setCopySuccess('Copied');
                setTimeout(() => setCopySuccess(''), 5000);
            },
            (err) => {
                setCopySuccess('Failed to copy URL');
                console.error('Failed to copy URL: ', err);
            }
        );
    };

    const onSuggestionsFetchRequested = async ({ value }: { value: string }) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_IDE_SERVER_URL}/events/lookup?query=${value}`);
            setSuggestions(response.data);
        } catch (error) {
            console.error('Failed to fetch suggestions:', error);
        }
    };

    const onSuggestionsClearRequested = () => {
        setSuggestions([]);
    };

    const getSuggestionValue = (suggestion: any) => suggestion.name;

    const renderSuggestion = (suggestion: any) => (
        <div>
            {suggestion.name}
        </div>
    );

    const handleInputChange = (event: React.FormEvent<any>, { newValue }: { newValue: string }) => {
        setInputValue(newValue);
    };

    const handleSubmit = async () => {
        setError('')
        const response = await handleNext(inputValue);
        if (response?.error) {
            console.log('response error', response.error)
            setInputValue('');
            setError(response.error)
        }
        setQR(response.data.QR)
        setURL(response.data.url)
    };

    return (
        <ModalContainer  show={show}>

            <ModalContent ref={modalRef}>
                {createdEventQR || QR?
                    <>
                        <CloseButton onClick={close}>&times;</CloseButton>
                        <img src={createdEventQR || QR} alt="QR" />
                        <Button onClick={handleCopyUrl}>{copySuccess || 'Copy URL'}</Button>
                    </>
                    :
                <>
                    <CloseButton onClick={close}>&times;</CloseButton>
                    <Title>Create a event</Title>
                    <Autosuggest
                        suggestions={suggestions}
                        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                        onSuggestionsClearRequested={onSuggestionsClearRequested}
                        getSuggestionValue={getSuggestionValue}
                        renderSuggestion={renderSuggestion}
                        inputProps={{
                            placeholder: 'Event name',
                            value: inputValue,
                            onChange: handleInputChange
                        }}
                    />
                    <Error>{error}</Error>
                    <Button onClick={handleSubmit}>CREATE</Button>
                </>
                }

            </ModalContent>
        </ModalContainer>
    );
};

export default CreateEventModal;

const ModalContainer = styled.div<{ show: boolean }>`
  display: ${(props) => (props.show ? 'block' : 'none')};
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
`;

const ModalContent = styled.div`
  position: relative;
  background-color: white;
  margin: 20% auto;
  padding: 20px;
  border: none;
  border-radius: 10px;
  width: 80%;
  min-height: 170px;
  max-height: 500px;
  max-width: 500px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
   
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const CloseButton = styled.span`
  position: absolute;
  top: 0px;
  right: 10px;

  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;

  &:hover {
    color: black;
    text-decoration: none;
  }
`;

const Title = styled.h2`
  text-align: center;
  color: black;
`;

// const Input = styled.input`
//   margin-bottom: 20px;
//   padding: 10px;
//   width: 50%;
//   font-size: 16px;
//   border: none;
//   border-radius: 10px;
//   box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
// `;

const Button = styled.button`
  padding: 10px 20px;
  background-color: #d9a329;
  border-radius: 20px;
  color: #fff;
  border: none;
  cursor: pointer;
  width: 200px;

  &:hover {
    background-color: #0056b3;
  }
`;

const Error = styled.p`
    color: red;    
`
