import axios from "axios";

export interface TwitterInfo {
    legacy: {
        name: string;
    };
}

export interface Profile {
    _id: string;
    username: string;
    fullName: string;
}

export interface Event {
    _id: string
    name: string
    creator: Profile
    participants: Profile[]
    invitations: { invitee: Profile, inviter: Profile }[]
}

export const createProfileAPI = async (linkedinInput: string): Promise<any> => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_IDE_SERVER_URL}/admin/users/generate-linkedin-profile`, {
            linkedinUrl: linkedinInput,
        })

        if (response.status === 201) {
            return response.data
        } else {
            console.error('Error creating profile', response)
            return {
                error: 'Profile is not exist.',
            }
        }
    } catch (error) {
        return {
            error: 'Error creating profile',
        }
    }
};

export const updateProfile = async (profileId: string, twitter: string) => {
    try {
        const response = await axios.put(`${process.env.REACT_APP_IDE_SERVER_URL}/profile/update/${profileId}`, {
            twitterUrl: twitter,
        });

        return response
    } catch (error) {
        throw error
    }
}
