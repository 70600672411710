import React, { useRef, useState, useMemo, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { ReactComponent as SearchIconSvg } from '../media/search-icon.svg';
import Trigger from 'rc-trigger';
import {useHistory} from "react-router-dom";

const StyledInput = styled.input`
  border: none;
  outline: none;
  background-color: transparent;
  flex: auto;
  min-width: 130px;
  height: 40px;
  padding-left: 20px;
  box-sizing: content-box;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;

  &::placeholder {
    color: grey';
  }
`;

const Container = styled.div.withConfig({
    shouldForwardProp: (prop) => !['focused', 'hasError'].includes(prop),
})<{ focused: boolean; hasError: boolean }>`
  cursor: pointer;
  position: relative;
  border: 1px solid
    ${(props) =>
    props.focused
        ? props.hasError
            ? 'red'
            : 'yellow'
        : 'grey'};
  outline: none;
  height: 44px;
  border-radius: 6px;
  background-color: ${(props) =>
    props.focused ? '#fff' : props.hasError ? 'yellow' : 'red'};
  display: flex;
  width: 100%;
  transition: all 0.3s;
  box-shadow: ${(props) =>
    props.focused ? `0 0 0 2px ${props.hasError ? 'yellow' : 'rgba(14, 151, 235, 0.1)'}` : 'none'};
`;

const StyledControl = styled.div`
  display: flex;
  flex: auto;
  align-items: center;
  width: 100%;
  overflow: hidden;
`;

const MenuWrapper = styled.div`
  display: flex;
  width: 100%;
  padding-top: 5px;
  position: absolute;
  z-index: 1050;
  overflow: hidden;
  left: 0;
  top: 100%;
  box-shadow: 0 2px 8px 0 rgba(0, 36, 46, 0.14);
  border-radius: 6px;
`;

const Menu = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #fff;
  padding: 8px 4px 10px;
`;

const StyledOption = styled.div<{ selected: boolean; isPreselected: boolean }>`
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  font-size: 14px;
  cursor: pointer;
  transition: background 0.15s ease;
  position: relative;
  height: 38px;
  display: flex;
  flex: auto;
  align-items: center;
  padding-left: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: 'black';
  background-color: ${(props) => {
    if (props.isPreselected) return `white`;
    return 'transparent';
}};

  &:not(:first-child) {
    margin-top: 1px;
  }

  &:hover {
    background-color: ${(props) => '#dbdee0'};
    border-radius: 3px;
  }
`;

const NotFoundContent = styled.div`
  cursor: default;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 38px;
  background-color: #fff;
  box-shadow: 0 2px 8px 0 rgba(0, 36, 46, 0.14);
  padding: 8px 4px 10px;
  top: 100%;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  font-size: 14px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${(props) => 'black'};
`;

const IndicatorContainer = styled.div`
  width: 35px;
  height: 100%;
  display: flex;
  align-items: center;
`;

export interface IIndicator {
    icon: 'search';
    position: 'left' | 'right';
}

const getPopupContainer = (node: HTMLElement): HTMLElement => {
    return node.closest('.ant-modal-wrap') || window.document.body;
};

const SearchIcon = styled(SearchIconSvg)<{ active?: boolean }>`
  color: ${(props) => (props.active === undefined || props.active ? 'black' : 'gray')};
`;

const Indicator: React.FC<IIndicator & { active: boolean }> = ({ icon, position, active }) => {
    return (
        <IndicatorContainer style={position === 'left' ? { justifyContent: 'flex-end' } : {}}>
            {icon === 'search' ? <SearchIcon active={active} /> : null}
        </IndicatorContainer>
    );
};

export type BaseOption = {
    key: string;
    value: string;
};

export type FilterOption<Option = BaseOption> = (value: string, candidate: Option) => boolean;

export type AutoCompleteProps<Option extends BaseOption = BaseOption> = {
    id?: string;
    name?: string;
    defaultValue?: string;
    value?: string;
    options: Option[];
    menuIsOpen?: boolean;
    menuListMaxSize?: number;
    minLengthSearchableValue?: number;
    placeholder?: string;
    notFoundContent?: string;
    blurOnEnter?: boolean;
    indicator?: IIndicator;
    searchDebounce?: number;
    hasError?: boolean;
    useKeyboardNavigation?: boolean;

    filterOption?: FilterOption<Option>;

    onSearch?: (value: string) => Promise<void>;

    onChange?: (value: string, option?: Option) => void;

    onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;

    onNativeBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;

    onNativeChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;

    renderOption?: (
        option: Option,
        inputValue: string,
        onSelect: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void,
        isPreselected?: boolean,
    ) => React.ReactElement;

    addNewOption?: (isPreselected: boolean) => JSX.Element;
    onAddNewOptionClick?: () => void;

    className?: string;
    isLoading?: boolean;
    tabIndex?: number;
};

const AutoComplete = function <Option extends BaseOption = BaseOption>(
    props: React.PropsWithChildren<AutoCompleteProps<Option>>,
): React.ReactElement {
    const {
        id,
        name,
        defaultValue,
        value,
        options,
        placeholder = '',
        notFoundContent,
        menuListMaxSize = 5,
        minLengthSearchableValue = 3,
        blurOnEnter = true,
        indicator,
        searchDebounce = 300,
        hasError = false,
        filterOption,
        onSearch,
        onChange,
        onKeyDown,
        onNativeBlur,
        onNativeChange,
        addNewOption,
        className,
        onAddNewOptionClick,
        isLoading,
        useKeyboardNavigation = true,
        tabIndex,
    } = props;
    const inputRef = useRef<HTMLInputElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);

    const history = useHistory();

    const [inputValue, setInputValue] = useState(defaultValue || '');

    const [preselectIndex, setPreselectIndex] = useState(0);

    useEffect(() => {
        value !== undefined && setInputValue(value);
    }, [value]);

    const preparedOptions = useMemo<Option[]>(() => {
        const result = filterOption ? options.filter((option) => filterOption(inputValue, option)) : options;

        return take(result, menuListMaxSize);
    }, [options, menuListMaxSize, inputValue, filterOption]);

    const [showLoader, setShowLoader] = useState(false);

    useEffect(() => {
        isLoading !== undefined && setShowLoader(isLoading);
    }, [isLoading]);

    const _onSearch = useDebounce(
        async (value: string) => {
            if (onSearch) {
                const result = onSearch(value);
                if (result instanceof Promise) {
                    try {
                        setShowLoader(true);
                        await result;
                    } finally {
                        setShowLoader(false);
                    }
                }
            }
        },
        searchDebounce,
        [onSearch],
    );

    const _onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;

        if (value !== inputValue) {
            setInputValue(value);

            if (value.length >= minLengthSearchableValue) {
                _onSearch(value);
            }
            onChange && onChange(value);
        }

        onNativeChange && onNativeChange(event);
    };

    const [focused, setFocused] = useState(false);

    const onContainerFocus = useCallback(() => {
        inputRef.current?.focus();
    }, []);

    const onInputFocus = useCallback(() => {
        setFocused(true);
    }, []);

    const onInputBlur = useCallback(
        (event: React.FocusEvent<HTMLInputElement>) => {
            setFocused(false);
            onNativeBlur && onNativeBlur(event);
        },
        [onNativeBlur],
    );

    const blur = useCallback(() => {
        const input = inputRef.current;
        input?.blur();
    }, []);

    const _onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (blurOnEnter && event.key && (event.key === 'Enter' || event.key === 'Tab')) {
            blur();
        }

        onKeyDown && onKeyDown(event);
    };

    const getOnSelectFor = (option: any) => {
        return (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            event.preventDefault();
            history.push(option.username);
            window.location.href = `/${option.username}`;
            const { value } = option;
            if (value !== inputValue) {
                setInputValue(value);
            }

            onChange && onChange(value, option);

            blur();
        };
    };

    const showOptions = focused && inputValue && inputValue.length >= minLengthSearchableValue && (preparedOptions.length || addNewOption);

    const callback = useCallback(
        (event: KeyboardEvent) => {
            if (!showOptions) return;
            switch (event.code) {
                case 'ArrowUp': {
                    event.stopPropagation();
                    event.preventDefault();
                    if (preselectIndex > 0) {
                        const newIndex = preselectIndex - 1;
                        setPreselectIndex(newIndex);
                    }
                    break;
                }
                case 'ArrowDown': {
                    event.stopPropagation();
                    event.preventDefault();
                    if (preparedOptions.length - 1 + (addNewOption ? 1 : 0) > preselectIndex) {
                        const newIndex = preselectIndex + 1;
                        setPreselectIndex(newIndex);
                    }
                    break;
                }
                case 'Enter': {
                    event.stopPropagation();
                    event.preventDefault();
                    if (preselectIndex > preparedOptions.length - 1) {
                        onAddNewOptionClick && onAddNewOptionClick();
                        return;
                    }
                    const option = preparedOptions[preselectIndex];
                    const { value } = option;
                    if (value !== inputValue) {
                        setInputValue(value);
                    }

                    onChange && onChange(value, option);

                    blur();
                    break;
                }
                case 'Escape':
                    blur();
                    break;
            }
        },
        [preparedOptions, preselectIndex, onChange, inputValue, blur, addNewOption, onAddNewOptionClick, showOptions],
    );

    useEffect(() => {
        if (useKeyboardNavigation) {
            document.addEventListener('keydown', callback);

            return () => {
                document.removeEventListener('keydown', callback);
            };
        }
    }, [callback, useKeyboardNavigation]);

    useEffect(() => {
        setPreselectIndex(0);
    }, [preparedOptions]);

    const showNotFound =
        focused && inputValue && inputValue.length >= minLengthSearchableValue && preparedOptions.length === 0 && notFoundContent !== undefined;

    const popup = (showOptions || showNotFound) && (
        <MenuWrapper>
            {showLoader && <NotFoundContent>Loading...</NotFoundContent>}
            {!showLoader && showOptions && (
                <Menu>
                    {preparedOptions.map((option, i) => {
                        const { key, value } = option;
                        const isPreselected = useKeyboardNavigation && preselectIndex === i;
                        if (props.renderOption) {
                            return props.renderOption(option, inputValue, getOnSelectFor(option), isPreselected);
                        }

                        return (
                            <StyledOption
                                key={key || i}
                                selected={value === inputValue}
                                onMouseDown={getOnSelectFor(option)}
                                title={value}
                                isPreselected={isPreselected}
                            >
                                {value}
                            </StyledOption>
                        );
                    })}
                    {!!addNewOption && preparedOptions.length === 0 && addNewOption(preselectIndex > preparedOptions.length - 1)}
                </Menu>
            )}
            {!showLoader && showNotFound ? <NotFoundContent>{notFoundContent}</NotFoundContent> : null}
        </MenuWrapper>
    );

    return (
        <Trigger
            action={['focus']}
            popup={popup}
            popupAlign={{
                points: ['tl', 'bl'],
                offset: [0, 3],
            }}
            stretch="width"
            getPopupContainer={getPopupContainer}
        >
            <Container focused={focused} ref={containerRef} hasError={hasError} className={className}>
                <StyledControl
                    onClick={onContainerFocus}
                    style={indicator && indicator.position === 'left' ? { flexDirection: 'row-reverse' } : {}}
                >
                    <StyledInput
                        type="text"
                        id={id || name}
                        name={name}
                        ref={inputRef}
                        placeholder={placeholder}
                        value={inputValue}
                        spellCheck={false}
                        autoCapitalize="none"
                        autoComplete="off"
                        autoCorrect="off"
                        onChange={_onChange}
                        onFocus={onInputFocus}
                        onBlur={onInputBlur}
                        onKeyDown={_onKeyDown}
                        tabIndex={tabIndex}
                    />
                    {indicator ? <Indicator {...indicator} active={focused} /> : null}
                </StyledControl>
            </Container>
        </Trigger>
    );
};

export default AutoComplete;


function take<T>(array: Array<T>, n: number = 1) {
    if (!(array != null && array.length)) {
        return [];
    }
    return [...array].slice(0, n < 0 ? 0 : n);
}

const useDebounce = <T extends (...args: any[]) => void>(callback: T, delay: number, dependencies: unknown[]) => {
    const timeout = useRef<ReturnType<typeof setTimeout>>();

    // @ts-ignore
    return useCallback((...args) => {
        if (timeout.current) {
            clearTimeout(timeout.current);
        }
        timeout.current = setTimeout(() => {
            callback.apply(null, args);
        }, delay);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, dependencies);
};
