import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import UserProfile from './components/UserProfile';
import Invite from "./components/Invite";

function App() {
  return (
      <Router>
        <Switch>
            <Route path="/invite" component={Invite} />
            <Route exact path="/:username" component={UserProfile} />
            <Route path="/" component={UserProfile} />
        </Switch>
      </Router>
  );
}

export default App;
