import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { FaLinkedin, FaTwitter } from 'react-icons/fa';
import { ReactComponent as SubstackIcon } from '../media/substack-icon.svg';
import { Column } from "./styled/components";
import { Underline } from "./ProfileSection";
import styled from "styled-components";
import useIsMobile from "../context/useMobileContext";

interface Activity {
    text: string;
    date: string | Date;
    url?: string;
    platform: string;
}

interface LinkedInActivities {
    likes?: Activity[];
    comments?: Activity[];
    posts?: Activity[];
}

interface TwitterActivities {
    tweets?: Activity[];
}

interface SubstackActivities {
    posts?: Activity[];
    likes?: Activity[];
}

interface IActivities {
    linkedinActivities?: LinkedInActivities;
    twitterActivities?: TwitterActivities;
    substackActivities?: SubstackActivities;
}

interface ActivitiesProps {
    profileId: string;
}

const parseRelativeDate = (relativeDate: string): Date => {
    const multipliers: { [key: string]: number } = {
        'h': 3600 * 1000,      // hours to milliseconds
        'd': 24 * 3600 * 1000, // days to milliseconds
        'w': 7 * 24 * 3600 * 1000, // weeks to milliseconds
        'mo': 30 * 24 * 3600 * 1000, // months (approx) to milliseconds
        'yr': 365 * 24 * 3600 * 1000 // years (approx) to milliseconds
    };

    const match = relativeDate.match(/^(\d+)([a-z]+)$/);
    if (!match) {
        return new Date()
    }

    const value = parseInt(match[1]);
    const unit = match[2];

    const multiplier = multipliers[unit];
    if (!multiplier) {
        return new Date();
    }

    const timeDiff = value * multiplier;

    const currentDate = new Date();
    const adjustedDate = new Date(currentDate.getTime() - timeDiff);

    return adjustedDate;
};

const Activities: React.FC<ActivitiesProps> = ({ profileId }) => {
    const [activities, setActivities] = useState<Activity[]>([]);
    const [visibleActivities, setVisibleActivities] = useState<Activity[]>([]);
    const [page, setPage] = useState(1);

    useEffect(() => {
        const fetchActivities = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_IDE_SERVER_URL}/profile/activity/${profileId}`);
                const data: IActivities = response.data;
                parseActivities(data)
            } catch (error) {
                console.error('Error fetching activities:', error);
            }
        };

        fetchActivities()
            // .then(() => pollActivities(profileId))
    //eslint-disable-next-line
    }, [profileId]);


    function parseActivities(activities: IActivities) {
        const linkedinLikes = activities.linkedinActivities?.likes || [];
        const linkedinComments = activities.linkedinActivities?.comments || [];
        const linkedinPosts = activities.linkedinActivities?.posts || [];
        const twitterTweets = activities.twitterActivities?.tweets || [];
        const substackPosts = activities.substackActivities?.posts || [];
        const substackLikes = activities.substackActivities?.likes || [];

        const allActivities: Activity[] = [
            ...linkedinLikes.map(activity => ({ ...activity, platform: 'linkedin', date: parseRelativeDate(activity.date as string) })),
            ...linkedinPosts.map(activity => ({ ...activity, platform: 'linkedin', date: parseRelativeDate(activity.date as string) })),
            ...linkedinComments.map(activity => ({ ...activity, platform: 'linkedin', date: parseRelativeDate(activity.date as string) })),
            ...twitterTweets.map(activity => ({ ...activity, platform: 'twitter', date: new Date(activity.date) })),
            ...substackPosts.map(activity => ({ ...activity, platform: 'substack', date: new Date(activity.date) })),
            ...substackLikes.map(activity => ({ ...activity, platform: 'substack', date: new Date(activity.date) }))
        ];

        console.log('allActivities', allActivities)

        allActivities.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

        setActivities(allActivities);
        setVisibleActivities(allActivities.slice(0, 12));
    }


    const loadMoreActivities = useCallback(() => {
        setVisibleActivities(prev => [
            ...prev,
            ...activities.slice(page * 12, (page + 1) * 12),
        ]);
        setPage(prev => prev + 1);
    }, [activities, page]);

    if (activities.length === 0) {
        return <div></div>;
    }

    const renderActivity = (activity: Activity, index: number) => (
        <ActvitiesContainer key={index}>
            <>
                {activity.platform === 'linkedin' && <FaLinkedin style={{ width: '20px', marginRight: '10px' }} />}
                {activity.platform === 'twitter' && <FaTwitter style={{ width: '20px', marginRight: '10px' }} />}
                {activity.platform === 'substack' && <SubstackIcon style={{ width: '20px', marginRight: '10px' }} /> }
                {activity.text} <DateLink url={activity.url} onClick={() => window.open(activity.url)}>({new Date(activity.date).toLocaleDateString('en-US', { month: 'long', day: 'numeric' })})</DateLink>
            </>
        </ActvitiesContainer>
    );

    return (
        <Container>
            <Title>
                <Column>
                    <p>Recent Activity:</p>
                    <Underline />
                </Column>
            </Title>
            {visibleActivities.map((activity, index) => renderActivity(activity, index))}
            {visibleActivities.length < activities.length && (
                <LoadMoreButton onClick={loadMoreActivities}>Load more</LoadMoreButton>
            )}
        </Container>
    );
};

export default Activities;

const Container = styled.div`
    margin-top: ${() => (useIsMobile() ? '0px' : '210px')};
    width: ${() => (useIsMobile() ? 'inherit' : '50%')};
    padding: ${() => (useIsMobile() ? '20px' : '0px')};
    flex: 1;
`;

const ActvitiesContainer = styled.div`
    margin-top: 15px;
    padding: 10px;
`;

const Title = styled.div`
    display: flex;
    position: relative;
    color: #3145e4;
    font-size: ${() => (useIsMobile() ? '20px' : '24px')};
`;

const LoadMoreButton = styled.button`
    padding: 10px 20px;
    margin: 20px auto;
    display: block;
    background-color: #3145e4;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    &:hover {
        background-color: #2a3cbe;
    }
`;

const DateLink = styled.span<{url: string | undefined}>`
    font-size: 12px;
    color: gray;
    cursor: ${(props) => props.url ? 'pointer' : 'inherit' };
    text-decoration: ${(props) => props.url ? 'underline' : 'inherit' };
`;
