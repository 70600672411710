import React, { createContext, useContext, useEffect, useState } from 'react';
import axios from 'axios';

interface AuthContextType {
    isAuthenticated: boolean;
    loading: boolean;
    authProfile: any;
    isCurrentProfile: (username: string) => boolean;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

const useAuth = () => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};

export { AuthContext, useAuth };

interface AuthProviderProps {
    children: React.ReactNode;
}

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const [authProfile, setProfile] = useState<any | null>(null);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const checkAuth = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_IDE_SERVER_URL}/v1/auth/token-is-valid`, {
                    withCredentials: true,
                    headers: {
                        'Authorization': localStorage.getItem('token')
                    }
                });
                const profileId = response.data.profile._id;

                if (profileId) {
                    setIsAuthenticated(true);
                    setProfile(response.data.profile)
                } else {
                    setIsAuthenticated(false);
                }
            } catch (error) {
                console.error('Failed to check authentication:', error);
                setIsAuthenticated(false);
            } finally {
                setLoading(false);
            }
        };

        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');

        if (token) {
            localStorage.setItem('token', token);
            urlParams.delete('token');
            const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
            window.history.replaceState({}, document.title, newUrl);
        }

        console.log('checkAuth')

        checkAuth();
    }, []);


    const isCurrentProfile = (username: string) => {
        return username.toLowerCase() === authProfile?.username?.toLowerCase();
    }

    return (
        <AuthContext.Provider value={{ isAuthenticated, loading, isCurrentProfile, authProfile }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;
