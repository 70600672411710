import React from 'react';
import { ColorRing } from 'react-loader-spinner';
import styled from "styled-components";
import useIsMobile from "../context/useMobileContext"; // Adjust the import as per your loader component

interface LoadingProps {
    message: string;
}

const Loading: React.FC<LoadingProps> = ({ message }) => (
    <>
        <Title>{message}</Title>
        <ColorRing
            visible={true}
            height="120"
            width="120"
            ariaLabel="color-ring-loading"
            wrapperStyle={{}}
            wrapperClass="color-ring-wrapper"
            colors={['#1f12b0', '#2136e3', '#f8b26a', '#ccd4f7', '#ffffff']}
        />
    </>
);

export default Loading;

const Title = styled.h2`
    font-size: ${() => (useIsMobile() ? '20px' : '24px')};
  text-align: center;
  color: black;
  margin-bottom:0;
  padding: 0;
  
  span {
    color: #5e19e9;
  }
`;
