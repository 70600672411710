export function getTwitterUrl(input: string): string {
    const twitterBaseUrl = 'https://twitter.com/';
    const XBaseUrl = 'https://x.com/'

    if (input.startsWith(twitterBaseUrl) || input.startsWith(XBaseUrl)) {
        return input;
    }

    return `${twitterBaseUrl}${input}`;
}

export function getLinkedInUrl(input: string): string {
    const linkedinBaseUrl = 'https://www.linkedin.com/in/';

    if (input.startsWith(linkedinBaseUrl)) {
        return input;
    }

    return `${linkedinBaseUrl}${input}`;
}
