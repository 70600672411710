import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { FaLinkedin } from 'react-icons/fa';

const Button = styled.button`
  margin-top: 5px;
  display: flex;
  align-items: center;
  background-color: #0077b5; /* LinkedIn brand color */
  color: white;
  border: none;
  border-radius: 20px;
  padding: 6px 14px;
  font-size: 13px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #005582; /* Darker shade for hover effect */
  }
`;

const IconWrapper = styled.div`
  width: 24px;
  height: 24px;
  margin-right: 10px;
  display: flex;
  align-items: center;
`;

interface LinkedInButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    children: ReactNode;
}

const LinkedInButton: React.FC<LinkedInButtonProps> = ({ children, ...props }) => {
    return (
        <Button {...props}>
            <IconWrapper>
                <FaLinkedin color="white" size={24} />
            </IconWrapper>
            {children}
        </Button>
    );
};

export default LinkedInButton;
