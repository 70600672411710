import "./style/index.css";
import styled from "styled-components";
import {useProfile} from "./context/ProfileContext";
import logo from './media/logo.png';
import mobileLogo from './media/logo-mobile2.png';
import AutoComplete, {BaseOption} from "./components/AutoComplete";
import axios from "axios";
import {useState} from "react";
import CreateUserModal from "./components/Modal";
import useWindowSize from "./hooks/useWindowSize";
import useIsMobile from "./context/useMobileContext";
import {useAuth} from "./context/AuthContext";


export interface IUserSearchItem {
    _id: string;
    fullName: string;
    username: string;
}

export type UserSearchOption = BaseOption & IUserSearchItem;

export default function RootLayout({
  children,
}: Readonly<{
  children: React.ReactNode;
}>) {
    const { authProfile } = useAuth();
    const { user } = useProfile();
    const [availableOptions, setAvailableOptions] = useState<UserSearchOption[]>([]);
    const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
    const [pendingUserName, setPendingUserName] = useState('');
    const [searchedBy, setSearchedBy] = useState<string | null>(null);

    const [showUserModal, setShowUserModal] = useState(false);
    const handleOpenUserModal = () => setShowUserModal(true);
    const handleCloseUserModal = () => setShowUserModal(false);

    const createProfile = async (inputValue: string): Promise<any> => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_IDE_SERVER_URL}/admin/users/generate-linkedin-profile`, {
                linkedinUrl: inputValue,
            })

            if (response.status === 201) {
                return response.data
            } else {
                console.error('Error creating profile', response)
                return {
                    error: 'Profile is not exist.',
                }
            }
        } catch (error) {
            return {
                error: 'Error creating profile',
            }
        }
    };

    const onSearch = async (query: string) => {
        const response = await axios.get(`${process.env.REACT_APP_IDE_SERVER_URL}/profile/lookup?query=${query}`)
        setAvailableOptions(
            response.data.map((c: any) => ({
                key: 'option-' + c._id,
                value: c.fullName,
                ...c,
            })),
        );
    }

    const onClearSearchState = () => {
        setSelectedUserId(null);
        setPendingUserName('');
        setSearchedBy(null);
        // onClearSearch();
    };

    const onChange = (value: string, option?: UserSearchOption) => {
        setSelectedUserId(null);

        if (option) {
            setSelectedUserId(option._id);
        } else {
            setPendingUserName(value);

            const isSearchClearedAfterRequest = !value && (searchedBy || selectedUserId);
            if (isSearchClearedAfterRequest) {
                onClearSearchState();
            }
        }
    };

    const goToProfile = () => {
        console.log('goToProfile')
        if (authProfile) {
            window.location.href = `/${authProfile.username}`
        }
    }

    const size: { width: number | undefined; height: number | undefined } = useWindowSize();
    const isMobile: boolean = !!size.width && size.width < 768;

    return (
      <body>
        <HeaderContainer>
            <LogoImageBox onClick={goToProfile} >
                {
                    isMobile ? <img src={mobileLogo} alt="Data.ml" width='50' height='50' /> :
                        <img src={logo} alt="Data.ml" width='190' height='50' />
                }
            </LogoImageBox>
            <ProfileTitle display={!isMobile}>
                Profiles {user && user?.fullName &&
                <>
                    <span style={{color: 'yellow', margin: "0 20px"}}> &gt;</span>
                    <span>{user.fullName}</span>
                </>
            }
            </ProfileTitle>
            <SearchContainer>
                <StyledAutoComplete
                    id="user-search"
                    placeholder="Search by name..."
                    options={availableOptions}
                    onSearch={onSearch}
                    minLengthSearchableValue={3}
                    menuListMaxSize={2}
                    onKeyDown={(e) => console.log('onkeydown', e)}
                    value={pendingUserName}
                    //@ts-ignore
                    onChange={onChange}
                    onAddNewOptionClick={() => {}}
                    addNewOption={(isPreselected) => (
                        <SearchOption onMouseDown={handleOpenUserModal} isPreselected={isPreselected}>
                            Create profile for "<strong>{pendingUserName}</strong>"...
                        </SearchOption>
                    )}
                />
            </SearchContainer>
            <CreateUserModal
                show={showUserModal}
                handleClose={handleCloseUserModal}
                handleNext={createProfile}
            />
        </HeaderContainer>
        {children}
      </body>
  );
}


const HeaderContainer = styled.header`
  height: 50px;
  background: ${() => useIsMobile() ? 'linear-gradient(to right, #1730ca, #6a1bf3)' : 'linear-gradient(to right, #5518e1, #6a1bf3)'};
  display: flex;
  color: white;
`;

const ProfileTitle = styled.div<{ display: boolean }>`
  display: ${(props) => props.display ? 'flex' : 'none'};
  justify-content: center;
  align-items: center;
  font-size: 18px;
  margin-left: 50px;
  font-family: "Lorin-Light";
  white-space: nowrap;
`;

const LogoImageBox = styled.div`
    border-right: 1px;
    border-color: white;
    cursor: pointer;
    z-index: 1;
`

export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${() => useIsMobile() ? 'center' : 'flex-start'};
  width: 100%;
  position: relative;
  margin-left: ${() => useIsMobile() ? '-50px' : '50px'};
`;

const StyledAutoComplete = styled(AutoComplete)`
  background-color: white;
  box-shadow: 0 1px 4px 0 rgba(0, 36, 46, 0.14);
  height: 40px;
  max-width: ${() => useIsMobile() ? '240px' : '300px'};
  border-radius: 10px;
`

export const SearchOption = styled.div<{ isPreselected: boolean }>`
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 13px;
  color: 'black';
  cursor: pointer;
  padding: 10px;
  background-color: ${(props) => {
    if (props.isPreselected) return `#dbdee0`;
    return 'transparent';
}};

  &:hover {
    background-color: 'red';
    border-radius: 3px;
  }
`;
