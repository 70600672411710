import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import {useHistory, useLocation, useParams} from 'react-router-dom';
import {useProfile} from "../context/ProfileContext";
import RootLayout from "../layout";
import ProfileSection from "./ProfileSection";
import {toast} from "react-toastify";
import {ColorRing} from 'react-loader-spinner';
import axios from "axios";
import EventSection from "./EventSection";
import {useAuth} from "../context/AuthContext";
import Activities from "./Activities";
import useIsMobile from "../context/useMobileContext";

type User = {
    username: string;
    fullName: string;
    email: string;
    posts: any[];
};

interface RouteParams {
    username: string;
}

const Profile = () => {
    const { username } = useParams<RouteParams>();
    const location = useLocation();
    const history = useHistory();

    const { isAuthenticated, authProfile } = useAuth();


    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        const onSuccess = urlParams.get('onSuccess');
        console.log('onSuccess', onSuccess)
        if (onSuccess === 'true') {
            toast('Profile is updated with LinkedIn');
            const newSearch = new URLSearchParams(location.search);
            newSearch.delete('onSuccess');
            history.replace({ search: newSearch.toString() });
        }
    }, [location.search]);

    const { user, setUser } = useProfile();
    const [error, setError] = useState<string | null>(null);
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true)
                const res = await axios.get<User>(`${process.env.REACT_APP_IDE_SERVER_URL}/profile/get/${username}`);
                const data = res.data;
                setError(null)
                setUser(data);
                document.title = `Data.ML | ${data.fullName}`;
                setLoading(false)
            } catch (error) {
                setError('User is not found.')
                setLoading(false)
            }
        };

        if (username) {
            fetchData();
        } else if (isAuthenticated && authProfile) {
            window.location.href = `/${authProfile.username}`;
        }
    }, [username, setUser]);


    if (isLoading) {
        return <RootLayout>
            <Container style={{"display": "flex", "alignItems": "center", "justifyContent": "center"}}>
                {error ? error: <ColorRing
                    visible={true}
                    height="120"
                    width="120"
                    ariaLabel="color-ring-loading"
                    wrapperStyle={{}}
                    wrapperClass="color-ring-wrapper"
                    colors={['#1f12b0', '#2136e3', '#f8b26a', '#ccd4f7', '#ffffff']}
                />}
            </Container>
        </RootLayout>;
    } else if (user) {
        return (
            <RootLayout>
                <Container>
                    <Column>
                        <ProfileSection profile={user}/>
                        <EventSection user={user} events={user.events} />
                    </Column>
                    <Activities profileId={user._id} />
                </Container>
            </RootLayout>
        );
    } else {
        return <RootLayout>
        </RootLayout>;
    }
};

export default Profile;

const Container = styled.div`
    background: #f3f1f8;
    height: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: ${() => (useIsMobile() ? 'column' : 'row')};
`

const Column = styled.div`
    display: flex;
    flex-direction: column;
    
    width: ${() => (useIsMobile() ? 'inherit' : '50%;')}; 
    flex: ${() => (useIsMobile() ? 'inherit' : '1')}; 
`
