import React, {useRef, useState} from 'react';
import styled from 'styled-components';
import 'react-toastify/dist/ReactToastify.css';
import {FaCheckCircle, FaLinkedin, FaSave} from "react-icons/fa";
import Avatar from "./Avatar";
import {useParams} from "react-router-dom";
import {AiOutlineEdit} from "react-icons/ai";
import useIsMobile from "../context/useMobileContext";
import axios from "axios";
import CreateEventModal from "./CreateEventModal";
import {useProfile} from "../context/ProfileContext";
import QRIcon from "../media/qr.svg";
import {useAuth} from "../context/AuthContext";
import {FaXTwitter} from "react-icons/fa6";
import {getLinkedInUrl, getTwitterUrl} from "../helpers/urls";

interface ProfileSectionProps {
    profile: any;
}

const ProfileSection: React.FC<ProfileSectionProps> = ({ profile }) => {
    const { isAuthenticated, isCurrentProfile } = useAuth();

    const { _id, fullName, headline, summary, profilePicture, isProfileClaimed } = profile;
    const { username } = useParams<{ username: string }>();

    const { setUser } = useProfile();

    const [headlineEdit, setHeadlineEdit] = useState(false);
    const [summaryEdit, setSummaryEdit] = useState(false);
    const [editedFullName, setEditedFullName] = useState(fullName);
    const [editedHeadline, setEditedHeadline] = useState(headline);
    const [editedSummary, setEditedSummary] = useState(summary);

    const [showEventModal, setShowEventModal] = useState(false);
    const [QRCode, setQRCode] = useState('');
    const [URL, setURL] = useState('');

    const inputRef = useRef(null)
    const textAreaRef = useRef(null)

    const handleLinkedInLogin = () => {
        window.location.href = `${process.env.REACT_APP_IDE_SERVER_URL}/profile/linkedin/oauth?username=${username}`;
    };

    const openCreateEventModal = () => {
        console.log('openCreateEventModal')
        setShowEventModal(true);
        setQRCode('')
        setURL('')
    }

    const openQRCode = () => {
        console.log('openQRCODE')
        setQRCode(profile.events[0].QR)
        setURL(profile.events[0].URL)
        setTimeout(() => setShowEventModal(true), 0)

    }

    const handleCreateEvent = async (name: string) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_IDE_SERVER_URL}/events`, {
                name,
                creatorId: _id
            })

            if (response.status === 201) {
                const res = await axios.get(`${process.env.REACT_APP_IDE_SERVER_URL}/profile/get/${username}`);
                const data = res.data;
                setUser(data);
               return response
            } else {
                console.error('Error creating event', response)
                return {
                    error: 'Error in creating event',
                }
            }
        } catch (error) {
            return {
                error: 'Error creating event',
            }
        }
    }

    const handleHeadlineEdit = () => {
        setHeadlineEdit(true)
        // @ts-ignore
        setTimeout(() => inputRef?.current?.focus(), 0)
    }

    const handleSummaryEdit = () => {
        setSummaryEdit(true)
        // @ts-ignore
        setTimeout(() => textAreaRef?.current?.focus(), 50)
    }

    const handleSaveHeadline = async () => {
        setHeadlineEdit(false);
        await axios.put(`${process.env.REACT_APP_IDE_SERVER_URL}/profile/update/${profile._id}`, {
            headline: editedHeadline ? editedHeadline : undefined,
            fullName: editedFullName ? editedFullName : undefined
        })
    };

    const handleSaveSummary = async () => {
        setSummaryEdit(false);
        await axios.put(`${process.env.REACT_APP_IDE_SERVER_URL}/profile/update/${profile._id}`, {
            summary: editedSummary
        })
    };

    return (
        <Container>
            <Row>
                <Avatar src={profilePicture} fullName={`${editedFullName}`} />
                <ProfileInfo>
                    {headlineEdit ?  <StyledNameInput
                        type="text"
                        ref={inputRef}
                        value={editedFullName}
                        onChange={(e) => setEditedFullName(e.target.value)}
                    /> : <FullName>
                        {editedFullName}
                        { isProfileClaimed && <FaCheckCircle size={22} style={{ color: 'green', marginLeft: '10px' }} /> }
                        { isCurrentProfile(username) && !headlineEdit && <EditButton onClick={handleHeadlineEdit}><AiOutlineEdit size={22} /></EditButton> }
                    </FullName>}
                    { isCurrentProfile(username) && headlineEdit && <SaveButton onClick={handleSaveHeadline}><FaSave size={22} /></SaveButton> }
                    <Row>
                        {profile.username &&
                            <LinkedInIcon href={getLinkedInUrl(profile.username)} target="_blank" rel="noopener noreferrer">
                                <FaLinkedin />
                            </LinkedInIcon>
                        }
                        {profile.twitterUrl &&
                            <TwitterIcon href={getTwitterUrl(profile.twitterUrl)} target="_blank" rel="noopener noreferrer">
                                <FaXTwitter />
                            </TwitterIcon>
                        }
                    </Row>
                    <Headline>
                        {headlineEdit ?
                                <StyledHeadlineInput
                                    type="text"
                                    value={editedHeadline}
                                    onChange={(e) => setEditedHeadline(e.target.value)}
                                /> : editedHeadline
                        }
                    </Headline>
                    <ClaimAndQR>
                        { isCurrentProfile(username) && <ClaimButton onClick={openCreateEventModal }>EVENT</ClaimButton> }
                        { !isAuthenticated && <ClaimButton onClick={handleLinkedInLogin}>SIGN IN</ClaimButton> }
                        { isCurrentProfile(username) && profile.events?.length > 0 ? <QRImage onClick={openQRCode} src={QRIcon} /> : null }
                    </ClaimAndQR>
                </ProfileInfo>
            </Row>
            <AboutMe>
                <Title>
                    <Column>
                        <p>About me:</p>
                        <Underline />
                    </Column>
                    {(isCurrentProfile(username) && !summaryEdit) && (
                        <EditSummaryButton onClick={handleSummaryEdit}><AiOutlineEdit size={22} /></EditSummaryButton>
                    )}
                    {(isCurrentProfile(username) && summaryEdit) && <SaveSummaryButton onClick={handleSaveSummary}><FaSave size={22} /></SaveSummaryButton> }
                </Title>
                {summaryEdit ?
                    <StyledTextarea
                        ref={textAreaRef}
                        value={editedSummary}
                        onChange={(e) => setEditedSummary(e.target.value)}
                    /> :  <Content> {editedSummary.slice(0, 460)} </Content> }
            </AboutMe>
            <CreateEventModal show={showEventModal} QR={QRCode} URL={URL} handleClose={() => setShowEventModal(false)} handleNext={handleCreateEvent} />
        </Container>
    );
};

export default ProfileSection;

const Row = styled.div`
    display: flex;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-bottom: 2px solid #ebe9ef;
  padding: 13px 13px;
  min-height: 250px;
`;


const ProfileInfo = styled.div`
  height: ${() => useIsMobile() ? '150px' : '170px'};
  min-width: ${() => useIsMobile() ? 'none' : '420px'};
  display: flex;
  flex-direction: column;
  justify-content: ${() => useIsMobile() ? 'flex-start' : 'space-between'};
  position: relative;
`;

const FullName = styled.h2`
  color: #3145e4;
  margin: 0 0 5px;
  font-size: ${() => (useIsMobile() ? '24px' : '38px')};
  display: flex;
  align-items: center;
`;

const Headline = styled.p`
  font-size: ${() => (useIsMobile() ? '12px' : '15px')};
  margin: 0 0 10px;
  font-family: "Lorin-Bold";
`;


const AboutMe = styled.div`
    width: 100%;
    margin-left: 10px;
    margin-top: ${() => useIsMobile() ? '20px' : '20px'};
`;

const ClaimButton = styled.button`
    position: relative;
    padding: ${() => useIsMobile() ? '5px' : '10px 20px'};
    background-color: #5126c7;
    border-radius: 20px;
    font-size: ${() => useIsMobile() ? '10px' : '11px'};
    width: ${() => useIsMobile() ? '80px' : '130px'};
    height: ${() => useIsMobile() ? '20px' : '34px'};
    color: white;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
    &:hover {
    background-color: #d9a329;
    }
`;


export const Title = styled.div`
    display: flex;
    position: relative;
    color: #3145e4;
    font-size: ${() => (useIsMobile() ? '20px' : '24px')};
`

export const Underline = styled.span`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 80px;
  background-color: #d9a329;
  height: 3px;
`;

const Content = styled.p`
    font-size: ${() => (useIsMobile() ? '13px' : '16px')};
    padding-top: 15px;
    padding-right: 25px;
    white-space: pre-line;
`

const EditButton = styled.button`
    background: transparent;
    border: none;
    cursor: pointer;
    color: blue;
    // position: absolute;
    top: ${() => useIsMobile() ? '5px' : '15px'};
    right:${() => useIsMobile() ? '-32px' : '-10px'};
`

const EditSummaryButton = styled.button`
    background: transparent;
    border: none;
    cursor: pointer;
    color: blue;
`

const SaveButton = styled.button`
    background: transparent;
    border: none;
    cursor: pointer;
    color: green;
    position: absolute;
    top: ${() => useIsMobile() ? '5px' : '15px'};
    right: 0;
`

const SaveSummaryButton = styled.button`
    background: transparent;
    border: none;
    cursor: pointer;
    color: green;
`

const StyledNameInput = styled.input`
    border: none;
    width: 100%;
    color: #3145e4;
    margin: 0 0 5px;
    font-size: ${() => (useIsMobile() ? '24px' : '38px')};
    background: transparent;
    font-weight: 700;
    font-family: "Lorin-Regular";
`;

const StyledHeadlineInput = styled.input`
  font-family: "Lorin-Bold";
  font-size: ${() => (useIsMobile() ? '12px' : '16px')};
  border: none;
  background:transparent;
  width: 100%;
`

const StyledTextarea = styled.textarea`
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100%;
    height: 190px;
    max-height: 190px;
    font-size: ${() => (useIsMobile() ? '13px' : '16px')};
    background: #f3f0f8;
    font-family: "Lorin-Regular";
    padding: 0;
    padding-top: 15px;
`;

export const Column = styled.div`
    display: flex;
    flex-direction: column;
`

const QRImage = styled.img`
    width: ${() => useIsMobile() ? '20px' : '35px'};
    height: ${() => useIsMobile() ? '20px' : '35px'};
    margin-left: 10px;
    cursor: pointer;
`

const ClaimAndQR = styled.div`
    margin-top: ${() => useIsMobile() ? '10px' : '0'};
    display: flex;
    align-items: center;
`;

const TwitterIcon = styled.a`
  margin-left: 5px;
  color: #1da1f2; /* Twitter Blue */
  &:hover {
    color: #0d95e8;
  }
`;

const LinkedInIcon = styled.a`
  color: #0077b5; /* LinkedIn Blue */
  &:hover {
    color: #005582;
  }
`;
