import { ToastContainer } from "react-toastify";
import {ProfileProvider} from "./ProfileContext";
import {MobileProvider} from "./useMobileContext";
import useWindowSize from "../hooks/useWindowSize";
import AuthProvider from "./AuthContext";

export function Providers({ children }: any) {
    const size = useWindowSize();
    const isMobile = !!size.width && size.width < 850;

    return (
        <AuthProvider>
            <ProfileProvider>
                <MobileProvider isMobile={isMobile}>
                    {children}
                    <ToastContainer />
                </MobileProvider>
            </ProfileProvider>
        </AuthProvider>
    );
}
