import React, {useEffect, useState} from 'react';
import { useLocation } from 'react-router-dom';
import {useAuth} from "../context/AuthContext";
import ProfileForm from "./ProfileForm";
import Loading from "./Loading";
import TwitterForm from "./TwitterForm";
import RootLayout from "../layout";
import styled from "styled-components";
import {Button, Column, Title} from "./styled/components";
import axios from "axios";
import {Event, updateProfile} from "../api/api";
import ScannerApp from "./Scanner";

const Invite: React.FC = () => {
    const { isAuthenticated, authProfile } = useAuth();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const eventId = queryParams.get('eventId');
    const inviterId = queryParams.get('inviterId');
    const showX = queryParams.get('showX');

    const [scanMode, setScanMode] = useState(false)
    const [copySuccess, setCopySuccess] = useState('')

    const [event, setEvent] = React.useState<Event>();
    const [error, setError] = React.useState<string>('');
    const [alreadyExistError, setAlreadyExistError] = React.useState<string>('');
    const [profile, setProfile] = React.useState<any>(null);
    const [isLinkedinLoading, setIsLinkedinLoading] = React.useState<boolean>(false);
    const [twitterIsLoading, setTwitterIsLoading] = React.useState<boolean>(false);
    const [twitterInfo, setTwitterInfo] = React.useState<any>(null);

    const connectTwitter = async (twitter: string) => {
        try {
            let profileData = profile || authProfile;
            if (profileData) {
                setTwitterIsLoading(true);
                setError('');

                const response = await updateProfile(profileData._id, twitter)

                if (response.status === 200) {
                    setTwitterInfo(response.data.twitterInfo);
                }
                setTwitterIsLoading(false);
            }
        } catch (error) {
            setError('Twitter account not found.');
            setTwitterIsLoading(false);
        }
    };

    const fetchEventById = async (eventId: string) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_IDE_SERVER_URL}/events/${eventId}`);
            setEvent(response.data);
        } catch (error) {
            setError('Error fetching event')
        }
    };


    const joinEvent = async (profileId: string, eventId: string, inviterId: string) => {
        try {
            await axios.post(`${process.env.REACT_APP_IDE_SERVER_URL}/events/${eventId}/invite`, {
                profileId,
                inviterId
            });
        } catch (error: any) {
            setError(error.response?.data?.message || 'Error joining event')
        }
    }



    useEffect(() => {
        if (eventId) {
            fetchEventById(eventId);
        }
    }, [eventId]);

    if (!eventId || !inviterId) {
        return (
            <RootLayout>
                <Container>
                    <ModalContent>
                        <Column>
                            <Title>Invalid URL</Title>
                        </Column>
                    </ModalContent>
                </Container>
            </RootLayout>
        );
    }

    const createProfileAndJoin = async (eventId: string, inviterId: string, linkedinValue: string): Promise<void> => {
        try {
            setIsLinkedinLoading(true);

            const response = await axios.post(`${process.env.REACT_APP_IDE_SERVER_URL}/events/${eventId}/invite`, {
                linkedinUrl: linkedinValue,
                inviterId
            });

            if (response.status === 201) {
                setProfile(response.data.profile);
                setIsLinkedinLoading(false);
            }
        } catch (error: unknown) {
            setIsLinkedinLoading(false);
            if (axios.isAxiosError(error)) {
                if (error.response?.status === 409) {
                    setAlreadyExistError(linkedinValue)
                } else {
                    setError(error.response?.data?.message || 'Error creating profile');
                }
            } else {
                setError('Error creating profile');
            }
        }
    };

    const joinWithoutCreatingProfile = async () => {
        setError('')
        setAlreadyExistError('')

        await joinEvent(authProfile._id, eventId, inviterId)
        window.location.href = `/${authProfile.username}`;
    };

    const joinWithCreatingProfile = async (linkedinValue: string) => {
        setError('')
        setAlreadyExistError('')

        await createProfileAndJoin(eventId, inviterId, linkedinValue);
    };

    const linkedinUrlGuide = () => {
        navigator.clipboard.writeText(`${window.location.origin}/linkedin-qr-guide`).then(() => {
            setCopySuccess('Copied')
        })
    }

    const handleQR = async (value: string) => {
        setScanMode(false)
        await joinWithCreatingProfile(value)
    }

    const connectLinkedin = () => {
        setIsLinkedinLoading(true)
        const inviteUrl = encodeURIComponent(window.location.href);
        window.location.href = `${process.env.REACT_APP_IDE_SERVER_URL}/profile/linkedin/oauth?eventId=${event?._id}&inviterId=${inviterId}&redirect=${inviteUrl}`;
    }


    const renderContent = () => {
        const isAlreadyInvited = event?.invitations?.some(invitation => invitation.invitee._id === authProfile?._id && invitation.inviter._id === inviterId);
        if (isAuthenticated) {
            if (isAlreadyInvited) {
                return (
                    <>
                        <Column>
                            <Title>You are already invited.</Title>
                        </Column>
                        <Button onClick={() => window.location.href = `/${authProfile.username}`}>GO</Button>
                    </>
                );
            } else {
                if (showX) {
                    return twitterIsLoading ?
                        <Loading message="X is connecting..." /> :
                        <TwitterForm connectTwitter={connectTwitter} profile={authProfile} twitterInfo={twitterInfo} />;
                } else {
                    return (
                        <>
                            <Column>
                                <Title>You are invited to <span>{event?.name}</span></Title>
                            </Column>
                            <Button onClick={joinWithoutCreatingProfile}>Join</Button>
                        </>
                    );
                }
            }
        } else {
            if (scanMode) {
               return (<>
                       <Title>Scan Linkedin QR code</Title>
                       <Button onClick={linkedinUrlGuide}>{copySuccess || 'Get URL of QR Page'}</Button>
                       <ScannerApp handleQR={handleQR} />
                   </>)
            }

            if (profile) {
                return twitterIsLoading ?
                    <Loading message="X is connecting..." /> :
                    <TwitterForm connectTwitter={connectTwitter} profile={profile} twitterInfo={twitterInfo} />;
            } else {
                return isLinkedinLoading ?
                    <Loading message='Profile is creating...' /> :
                    <ProfileForm
                        error={error}
                        alreadyExistError={alreadyExistError}
                        handleSubmit={joinWithCreatingProfile}
                        turnOnScanMode={() => setScanMode(true)}
                        event={event}
                        connectLinkedin={connectLinkedin}
                    />;
            }
        }
    };

    if (!event) {
        return (
            <RootLayout>
                <Container>
                    <ModalContent>
                        <Column>
                            <Title>Event not found</Title>
                        </Column>
                    </ModalContent>
                </Container>
            </RootLayout>
        );
    }

    return (
        <RootLayout>
            <Container>
                <ModalContent>
                    {renderContent()}
                </ModalContent>
            </Container>
        </RootLayout>
    );
};

export default Invite;


const Container = styled.div`
  background-color: #f3f0f8;
  height: calc(100vh - 50px);
  display: flex;
  justify-content: center;
  align-items: center;
`

const ModalContent = styled.div`
  position: relative;
  background-color: white;
  padding: 20px;
  border: none;
  border-radius: 10px;
  width: 80%;
  min-height: 190px;
  max-height: 300px;
  max-width: 500px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
   
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;



