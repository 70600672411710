import styled from "styled-components";
import useIsMobile from "../../context/useMobileContext";

export const CloseButton = styled.span`
  position: absolute;
  top: 0px;
  right: 10px;

  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;

  &:hover {
    color: black;
    text-decoration: none;
  }
`;


export const Title = styled.h2`
  font-size: ${() => (useIsMobile() ? '20px' : '24px')};
  text-align: center;
  color: black;
  margin-bottom:0;
  padding: 0;
  
  span {
    color: #5e19e9;
  }
`;

export const SubTitle = styled.h3`
    font-size: ${() => (useIsMobile() ? '16px' : '18px')};
    text-align: center;
    color: #555;
`;



export const Input = styled.input`
  margin: 15px 0;
  padding: 10px;
  width: 100%;
  font-size: 16px;
  border: none;
  border-radius: 10px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
`;


export const Button = styled.button`
  padding: 10px 20px;
  margin: 5px 0;
  background-color: #d9a329;
  border-radius: 20px;
  color: #fff;
  border: none;
  cursor: pointer;
  width: 200px;

  &:hover {
    background-color: #0056b3;
  }
`;

export const Error = styled.p`
    color: red;    
`


export const Column = styled.div`
    display: FLEX;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const Login = styled.a`
    color: #5e19e9;
    margin-bottom: 10px;
    text-decoration: underline;
    font-weight: 800;
`

export const OptionalTitle = styled.p`
color: black
`;
