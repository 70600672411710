import React, {useState} from 'react';
import {Input, SubTitle, Title, Error, Button, Column, Login} from "./styled/components";
import {Event} from '../api/api';
import LinkedInButton from "./LinkedinButton";

interface ProfileFormProps {
    error?: string;
    alreadyExistError?: string;
    handleSubmit?: (value: string) => void;
    createProfile?: (value: string) => void;
    event?: Event;
    turnOnScanMode?: () => void;
    connectLinkedin?: () => void;
}

const ProfileForm: React.FC<ProfileFormProps> = ({ handleSubmit, event, createProfile, error, alreadyExistError, connectLinkedin }) => {

    const [linkedinValue, setLinkedinValue] = useState<string>('');
    // const [scanMode, setScanMode] = useState<boolean>(false);

    const handleLinkedinChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setLinkedinValue(e.target.value);
    };

    const handleCreateProfile = () => {
        if (createProfile) {
            createProfile(linkedinValue)
        }
    }

    const handleCreateProfileAndJoin = () => {
        if (handleSubmit) {
            handleSubmit(linkedinValue)
        }
    }

    const linkedInLoginUrl = () => {
        const inviteUrl = encodeURIComponent(window.location.href);
        return `${process.env.REACT_APP_IDE_SERVER_URL}/profile/linkedin/oauth?username=${alreadyExistError}&redirect=${inviteUrl}`;
    };

    return event ? (
        <>
            <div>
                <Title>You invited to <span>{event?.name}</span></Title>
                <SubTitle>Type your LinkedIn to create profile</SubTitle>
            </div>
            <Column>
                <Input placeholder="Username or URL" type="text" value={linkedinValue} onChange={handleLinkedinChange}/>
                {alreadyExistError &&
                    <>
                        <Error>Profile already exists</Error>
                        <Login href={linkedInLoginUrl()}>LOGIN</Login>
                    </>}
                <Error>{error}</Error>
                <Button onClick={handleCreateProfileAndJoin}>NEXT</Button>
                {/*<OptionalTitle onClick={turnOnScanMode}>Or go to scan mode</OptionalTitle>*/}
            </Column>
            <Column>
                <SubTitle>or</SubTitle>
                <LinkedInButton onClick={connectLinkedin}>Connect with LinkedIn</LinkedInButton>
            </Column>
        </>
    ) :
        <>
            <Title>Create a profile</Title>
            <Input placeholder="Type LinkedIn username or URL" type="text" value={linkedinValue} onChange={handleLinkedinChange} />
            {alreadyExistError &&
                <>
                    <Error>Profile already exists</Error>
                    <Login href={linkedInLoginUrl()}>LOGIN</Login>
                </>}
            <Error>{error}</Error>
            <Button onClick={handleCreateProfile}>NEXT</Button>
            {/*<OptionalTitle onClick={turnOnScanMode}>Or go to scan mode</OptionalTitle>*/}
        </>
};

export default ProfileForm;
