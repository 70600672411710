import React, {useState} from 'react';
import Loading from "./Loading";
import TwitterForm from "./TwitterForm";
import styled from "styled-components";
import {Button, CloseButton, Title} from "./styled/components";
import ProfileForm from "./ProfileForm";
import {Profile, updateProfile} from "../api/api";
import ScannerApp from "./Scanner";
import axios from "axios";

interface MyModalProps {
    show: boolean;
    handleClose: () => void;
    handleNext: (inputValue: string) => Promise<Profile>;
}

const Modal: React.FC<MyModalProps> = ({ show, handleClose, handleNext }) => {
    const [profile, setProfile] = useState<Profile | null>(null);
    const [isLinkedinLoading, setIsLinkedinLoading] = useState<boolean>(false);

    const [error, setError] = useState<string>('');
    const [alreadyExistError, setAlreadyExistError] = React.useState<string>('');

    const [twitterInfo, setTwitterInfo] = useState<any | null>(null);
    const [twitterIsLoading, setTwitterIsLoading] = useState<boolean>(false);

    const [scanMode, setScanMode] = useState(false)
    const [copySuccess, setCopySuccess] = useState('')


    const connectTwitter = async (twitter: string) => {
        try {
            if (profile) {
                setTwitterIsLoading(true);
                setError('');

                const response = await updateProfile(profile._id, twitter)

                if (response.status === 200) {
                    setTwitterInfo(response.data.twitterInfo);
                    if (!response.data.twitterInfo) {
                        window.location.href = `/${profile?.username}`;
                    }
                }
                setTwitterIsLoading(false);
            }
        } catch (error) {
            setError('Twitter account not found.');
            setTwitterIsLoading(false);
        }
    };

    const createProfile = async (linkedinValue: string) => {
        try {
            setIsLinkedinLoading(true);
            const response = await axios.post(`${process.env.REACT_APP_IDE_SERVER_URL}/admin/users/generate-linkedin-profile`, {
                linkedinUrl: linkedinValue,
            })

            if (response.status === 201) {
                setProfile(response.data)
            }

            setIsLinkedinLoading(false);
        } catch (error) {
            setIsLinkedinLoading(false);

            if (axios.isAxiosError(error)) {
                if (error.response?.status === 409) {
                    setAlreadyExistError(linkedinValue)
                } else {
                    setError(error.response?.data?.message || 'Error creating profile');
                }
            } else {
                setError('Error creating profile');
            }
        }
    };

    const close = () => {
        handleClose();
        setProfile(null);
        setScanMode(false)
        setCopySuccess('')
    }

    const handleQR = async (value: string) => {
        setScanMode(false)
        await createProfile(value)
    }

    const renderContent = () => {
        if (isLinkedinLoading) {
            return <Loading message="Profile is creating..." />;
        } else if (profile) {
            return twitterIsLoading ?
                <Loading message="X is connecting..." /> :
                <TwitterForm
                    connectTwitter={connectTwitter}
                    twitterInfo={twitterInfo}
                    profile={profile}
                    error={error}
                />;
        } else {
            return <ProfileForm
                createProfile={createProfile}
                error={error}
                alreadyExistError={alreadyExistError}
                turnOnScanMode={() => setScanMode(true)}
            />
        }
    };

    const linkedinUrlGuide = () => {
        navigator.clipboard.writeText(`${window.location.origin}/linkedin-qr-guide`).then(() => {
            setCopySuccess('Copied')
        })
    }

    return (
        <ModalContainer show={show}>
            <ModalContent scanMode={scanMode}>
                <CloseButton onClick={close}>&times;</CloseButton>
                {scanMode ?
                    <>
                        <Title>Scan Linkedin QR code</Title>
                        <Button onClick={linkedinUrlGuide}>{copySuccess || 'Get URL of QR Page'}</Button>
                        <ScannerApp handleQR={handleQR} />
                    </>
                    : renderContent()}
            </ModalContent>
        </ModalContainer>
    );
};

export default Modal;

const ModalContainer = styled.div<{ show: boolean }>`
  display: ${(props) => (props.show ? 'block' : 'none')};
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
`;

const ModalContent = styled.div<{ scanMode: boolean }>`
  position: relative;
  background-color: white;
  margin: 20% auto;
  padding: 20px;
  border: none;
  border-radius: 10px;
  width: 80%;
  min-height: 170px;
  max-height: ${(props) => props.scanMode ? 'none' : '300px' }
  max-width: 500px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
   
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;
