import React from 'react';
import styled from 'styled-components';
import useIsMobile from "../context/useMobileContext";

interface AvatarProps {
    src: string | null;
    fullName: string;
}

const AvatarContainer = styled.div<{ src: string | null }>`
    min-width: ${() => (useIsMobile() ? '100px' : '180px')};
    width: ${() => (useIsMobile() ? '100px' : '180px')};
    height: ${() => (useIsMobile() ? '100px' : '180px')};
    border-radius: 50%;
    margin-right: 20px;
    background: ${(props) => (props.src ? `url(${props.src})` : '#ccc')};
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: ${() => (useIsMobile() ? '15px' : '24px')};
`;

const Avatar: React.FC<AvatarProps> = ({ src, fullName }) => {
    const initials = fullName
        .split(' ')
        .map((part) => part.charAt(0).toUpperCase())
        .join('');

    return (
        <AvatarContainer src={src}>
            {src ? null : initials}
        </AvatarContainer>
    );
};

export default Avatar;

