import React, { useState } from 'react';
import styled from 'styled-components';
import {FaChevronDown, FaChevronUp, FaLinkedin} from "react-icons/fa";
import {FaXTwitter} from 'react-icons/fa6';
import useIsMobile from "../context/useMobileContext";
import {getLinkedInUrl, getTwitterUrl} from "../helpers/urls";
import {useProfile} from "../context/ProfileContext";

export interface Participant {
    _id: string;
    username: string;
    fullName: string;
    linkedinUrl: string;
    twitterUrl: string;
}

export interface Event {
    _id: string;
    name: string;
    createdAt: string;
    creator: Participant;
    participants: Participant[];
}

interface EventSectionProps {
    events: Event[];
    user: any;
}

const EventSection: React.FC<EventSectionProps> = ({ user, events }) => {
    const [openEventId, setOpenEventId] = useState<string | null>(null);

    const toggleEvent = (eventId: string) => {
        setOpenEventId(openEventId === eventId ? null : eventId);
    };

    return events?.length > 0 ? (
        <Container>
            <Title>
                <Column>
                    <p>Events:</p>
                    <Underline />
                </Column>
            </Title>
            <Section>
                {events.map((event) => (
                    <EventCard key={event._id} onClick={() => toggleEvent(event._id)}>
                        <div>
                            <EventTitle>{event.name}</EventTitle>
                            <EventDate>{new Date(event.createdAt).toLocaleDateString('en-US', {
                                day: 'numeric',
                                month: 'long',
                                year: 'numeric',
                            })}</EventDate>
                            {openEventId === event._id && (
                                <ParticipantList>
                                    {event.participants.map((participant) => user._id !== participant._id && (
                                        <ParticipantItem key={participant._id}>
                                            <a href={`/${participant.username}`} rel="noopener noreferrer">
                                                {participant.fullName}
                                            </a>
                                            {participant.username &&
                                                <LinkedInIcon href={getLinkedInUrl(participant.username)} target="_blank" rel="noopener noreferrer">
                                                    <FaLinkedin />
                                                </LinkedInIcon>
                                            }
                                            {participant.twitterUrl &&
                                                <SocialIcon href={getTwitterUrl(participant.twitterUrl)} target="_blank" rel="noopener noreferrer">
                                                    <FaXTwitter />
                                                </SocialIcon>
                                            }
                                        </ParticipantItem>
                                    ))}
                                </ParticipantList>
                            )}
                        </div>
                        <IconWrapper>
                            {openEventId === event._id ? <FaChevronUp color={"blue"} /> : <FaChevronDown color={"blue"} />}
                        </IconWrapper>
                    </EventCard>
                ))}
            </Section>
        </Container>
    ) : <></>;
};

export default EventSection;

const Container = styled.div`
    padding: 20px;
`;

export const Section = styled.section`
    padding: 20px;
    border-radius: 8px;
    max-width: 600px;
    position: relative;
`;

export const EventCard = styled.div`
    margin-bottom: 15px;
    padding: 10px;
    background-color: #edebf7;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 500px;
    position: relative;
`;

export const EventTitle = styled.h2`
    margin: 0;
    font-size: 1.2em;
    color: #333;
`;

export const EventDate = styled.p`
    margin: 0;
    font-size: 0.7em;
    color: #777;
`;

export const ParticipantList = styled.ul`
    list-style: none;
    padding: 0;
    margin: 10px 0 0;
`;

export const ParticipantItem = styled.li`
    margin: 5px 0;
    font-size: 0.9em;
    color: #555;
    
    a {
        color: black;
    }
`;

export const IconWrapper = styled.div`
    display: flex;
    align-items: center;
    font-size: 1.2em;
    color: #666;
    position: absolute;
    right: 10px;
    top: 15px;
`;

const Underline = styled.span`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 50px;
    background-color: #d9a329;
    height: 3px;
`;

const Title = styled.h1`
    color: #3145e4;
    font-size: ${() => (useIsMobile() ? '20px' : '24px')};
`;

const Column = styled.div`
    position: relative;
`;

const SocialIcon = styled.a`
  margin-left: 8px;
  color: #1da1f2; /* Twitter Blue */
  &:hover {
    color: #0d95e8;
  }
`;

const LinkedInIcon = styled(SocialIcon)`
  color: #0077b5; /* LinkedIn Blue */
  &:hover {
    color: #005582;
  }
`;
