'use client'
import React, { createContext, useContext, useState } from 'react';


type ProfileContextType = {
    user: any | null;
    setUser: (user: any | null) => void;
};

const ProfileContext = createContext<ProfileContextType>({
    user: null,
    setUser: () => {},
});

export const useProfile = () => useContext(ProfileContext);

export const ProfileProvider: React.FC<any> = ({ children }: any) => {
    const [user, setUser] = useState<any | null>(null);

    return (
        <ProfileContext.Provider value={{ user, setUser }}>
            {children}
        </ProfileContext.Provider>
    );
};
