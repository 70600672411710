import React from 'react';
import { Scanner } from '@yudiel/react-qr-scanner';


const ScannerApp: React.FC<{ handleQR: any }> = ({handleQR}) => {

    const handleScan = (result: any) => {
        if (result) {
            const linkedinUrl = result[0].rawValue

            if (linkedinUrl && linkedinUrl.startsWith('https://www.linkedin.com/in/')) {
                handleQR(removeQueryParameters(linkedinUrl))
            }
        }
    }
    return (
        <div>
            <Scanner onScan={handleScan} />
        </div>
    );
};

export default ScannerApp;

function removeQueryParameters(url: string) {
    let urlObj = new URL(url);

    urlObj.search = '';

    return urlObj.toString();
}
